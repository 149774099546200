<template>
  <div class="page">
    <div class="inv-box" v-if="this.$route.query.status">
          <a-form-model style="border: 1px solid rgb(245, 245, 245); padding: 10px 15px;margin-bottom:16px;">
            <div class="ant-descriptions-title">买方信息</div>
              <div  style="width:100%;display: flex;flex-wrap:wrap;">
                <a-form-model-item label="公司名称" required style="width: 22%;">
                  <span>{{ selectInvoice.invoiceHeading }}</span>
                </a-form-model-item>
                <a-form-model-item label="发票抬头" required style="width: 22%;">
                  <span>{{ selectInvoice.invoiceHeading }}</span>
                </a-form-model-item>
                <a-form-model-item label="统一社会信用代码/纳税人识别号" required style="width: 22%;">
                    <span>{{ selectInvoice.dutyParagraph }}</span>
                </a-form-model-item>
                <a-form-model-item label="开户行" :required="invoiceType == 2" style="width: 22%;">
                    <span>{{ selectInvoice.bank }}</span>
                </a-form-model-item>
                <a-form-model-item label="发票类型" :required="invoiceType == 2" style="width: 22%;">
                    <span>{{ selectInvoice.invoiceTypeName }}</span>
                </a-form-model-item>
                <a-form-model-item label="账号" :required="invoiceType == 2" style="width: 22%;">
                    <span>{{ selectInvoice.bankAccount }}</span>
                </a-form-model-item>
                <a-form-model-item label="地址" :required="invoiceType == 2" style="width: 22%;">
                    <span>{{ selectInvoice.address }}</span>
                </a-form-model-item>
                <a-form-model-item label="电话" :required="invoiceType == 2" style="width: 22%;">
                    <span>{{ selectInvoice.phone }}</span>
                </a-form-model-item>
            </div>
          </a-form-model>
        </div>
    <div style="display: flex; justify-content: left;align-items: center;" class="page-info" v-show="current == 0 && !this.$route.query.status">
      <span style="margin-right: 10px;font-size: 15px" v-if="invoicingType != 3 && !this.$route.query.status">合计可开票金额：¥{{ invoiceAmount.toFixed(2) }}</span>
      <!-- 关联预开票 -->
      <span style="margin-right: 10px;font-size: 15px" v-if="this.$route.query.status">开票金额：¥{{ form.actualAmount }}</span>
      <!-- 新增预开票 -->
      <!-- 新增 -->
      <div style="display: flex;" v-if="!this.$route.query.status">
        <a-button v-if="current != 0" :disabled="current == 0" @click="current--">上一步</a-button>
        <a-button v-if="current != 1  && invoicingType != 3" :disabled="selectedRowsList.length == 0 || !queryData.invoiceId" type="primary" @click="next">下一步</a-button>
        <!-- 新增预开票 glAmout  -->
        <a-button v-if="current == 1"  type="primary" @click="save" style="margin-left: 10px;">提交</a-button>
      </div>
      <!-- 关联 -->
      <div style="display: flex;" v-else>
        <a-button v-if="current != 0" :disabled="current == 0" @click="current--">上一步</a-button>
        <a-button v-if="current != 1" :disabled="selectedRowsList.length == 0" type="primary" @click="current++">关联</a-button>
        <a-button v-if="current == 1"  type="primary" @click="saveGl" style="margin-left: 10px;">立即关联</a-button>
      </div>
    </div>
    <ul >
      <li v-show="current == 0 || this.$route.query.status">
        <div style="display: flex;" v-if="!this.$route.query.status">
          <a-form-item required label="客户" style="width: 300px">
            <a-select
              v-model="queryData.customerId" show-search
              :filter-option="(input, option) => {return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0);}"
              style="width: 100%"
              placeholder="请选择客户"
              :default-value="null"
              :disabled="this.$route.query.status"
              option-label-prop="label"
              @change="selectChange"
            >
              <a-select-option
                :value="item.id"
                :label="item.name"
                v-for="(item, index) in customerData"
                :key="index"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
        </a-form-item>
        <a-form-item required label="发票抬头" style="width: 300px;margin-left:10px;" v-if="invoiceList.length > 0 ">
          <a-select v-model="queryData.invoiceId" show-search :filter-option="(input, option) => {return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0);}"
            style="width: 100%"
            placeholder="请选择发票抬头"
            :disabled="this.$route.query.status"
            :default-value="null"
            option-label-prop="label" @change="selectInvoiceChange">
            <a-select-option
              :value="item.id"
              :label="item.invoiceHeading"
              v-for="(item, index) in invoiceList"
              :key="index">
              {{ item.invoiceHeading }}
            </a-select-option>
          </a-select>
        </a-form-item>
        </div>
        <div v-if="invoiceList.length == 0 && queryData.customerId">
          <a-icon type="warning" theme="filled" style="color:red;" />
          当前客户暂无开票信息，请在我的客户中进行添加开票信息<a href="#" style="border-bottom:1px solid;" @click="$router.push('/sfa_customer/sfa_customerMineList')">前往添加</a>
        </div>
        <div class="page-table" >
          <a-tabs :default-active-key="invoicingType" @change="callback">
            <a-tab-pane key="1" >
              <span slot="tab">
                销售单
                <span class="tips-rad">{{selectedRowsList1.length}}</span>
                <a-tooltip>
                  <template slot="title">
                    <span>由客户经理在CRM中自行提交的申请</span>
                  </template>
                  <a-icon type="question-circle" />
                </a-tooltip>
              </span>
              <div>
                <a-table
                :columns="columns"
                :dataSource="dataSource"
                bordered
                :loading="tabLoading"
                :pagination="false"
                :rowKey="(record, index) => index"
                :scroll="{ x: 1500 }"
                :row-selection="rowSelection1"
                class="components-table-demo-nested"
              >
                <template slot="submitTime" slot-scope="text">
                  <span v-if="text">{{ formatDateMin(text) }}</span>
                  <span v-else>-</span>
                </template>
                <template slot="expectedProcurementTime" slot-scope="text">
                  <span v-if="text">{{ formatDate(text) }}</span>
                  <span v-else>-</span>
                </template>
                <template slot="createTime" slot-scope="text">
                  <span v-if="text">{{ formatDateMin(text) }}</span>
                  <span v-else>-</span>
                </template>
                <template slot="name" slot-scope="text">
                  <a-popover>
                    <template slot="content">
                      {{ text }}
                    </template>
                    {{ text }}
                  </a-popover>
                </template>
                <template slot="customerName" slot-scope="text">
                  <a-popover>
                    <template slot="content">
                      {{ text }}
                    </template>
                    {{ text }}
                  </a-popover>
                </template>
              </a-table>
              <div class="page-box">
                <a-pagination
                  :total="total"
                  :current="queryData.page + 1"
                  :pageSize="queryData.size"
                  @change="handlePageChange"
                />
              </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="4" force-render> 
              <span slot="tab">
                货堆
                 <span class="tips-rad">{{selectedRowsList2.length}}</span>
                <a-tooltip >
                  <template slot="title">
                    <span>如未查询到记录请与采购部确认是否已上传至OMS</span>
                  </template>
                  <a-icon type="question-circle" />
                </a-tooltip>
              </span>
              <div>
                <a-table
                :columns="pileColumns"
                :dataSource="pileDataSource"
                bordered
                :loading="pileLoading"
                :pagination="false"
                :rowKey="(record, index) => index"
                :scroll="{ x: 1500 }"
                :row-selection="rowSelection2"
                class="components-table-demo-nested"
              >
                <template slot="orderTime" slot-scope="text">
                  <span v-if="text">{{ formatDateMin(text) }}</span>
                  <span v-else>-</span>
                </template>

                <template slot="invoice" slot-scope="text">
                  <span v-if="text == 1">可开票</span>
                  <span v-if="text == 2">不可开票</span>
                </template>

                <template slot="createTime" slot-scope="text">
                  <span v-if="text">{{ formatDateMin(text) }}</span>
                  <span v-else>-</span>
                </template>
              </a-table>
              
              <div class="page-box">
                <a-pagination
                  :total="pileTotal"
                  :current="pileParams.page + 1"
                  :pageSize="pileParams.size"
                  @change="handlePageChange1"
                />
              </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="5" force-render> 
              <span slot="tab">
                宅配
                 <span class="tips-rad">{{selectedRowsList3.length}}</span>
                <a-tooltip >
                  <template slot="title">
                    <span>如未查询到记录请与采购部确认是否已上传至OMS</span>
                  </template>
                  <a-icon type="question-circle" />
                </a-tooltip>
              </span>
              <div>
                <a-table
                :columns="resColumns"
                :dataSource="resDataSource"
                bordered
                :loading="resLoading"
                :pagination="false"
                :rowKey="(record, index) => index"
                :scroll="{ x: 1500 }"
                :row-selection="rowSelection3"
                class="components-table-demo-nested"
              >
                <template slot="submitTime" slot-scope="text">
                  <span v-if="text">{{ formatDateMin(text) }}</span>
                  <span v-else>-</span>
                </template>

                <template slot="expectedProcurementTime" slot-scope="text">
                  <span v-if="text">{{ formatDate(text) }}</span>
                  <span v-else>-</span>
                </template>
                <template slot="createTime" slot-scope="text">
                  <span v-if="text">{{ formatDateMin(text) }}</span>
                  <span v-else>-</span>
                </template>

                <template slot="outCardOxp" slot-scope="text">
                  <span v-if="text">{{ text.amount }}</span>
                  <span v-else>-</span>
                </template>
                
                <template slot="name" slot-scope="text">
                  <a-popover>
                    <template slot="content">
                      {{ text }}
                    </template>
                    {{ text }}
                  </a-popover>
                </template>
                <template slot="invoice" slot-scope="text">
                  <span v-if="text == 1">可开票</span>
                  <span v-if="text == 2">不可开票</span>
                </template>
                <template slot="customerName" slot-scope="text">
                  <a-popover>
                    <template slot="content">
                      {{ text }}
                    </template>
                    {{ text }}
                  </a-popover>
                </template>
              </a-table>
              <div class="page-box">
                <a-pagination
                  :total="resTotal"
                  :current="resParams.page + 1"
                  :pageSize="resParams.size"
                  @change="handlePageChange2" />
              </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="3" tab="预开票">
              <a-alert   message="客户需要先开具发票后才能采购卡券、货堆、宅配等，则可选择预开票栏目，客户采购完成请记录关联相关销售单避免造成欠票" banner />
              <div style="margin-top: 20px" v-if="this.$route.query.status">
                <!-- 关联预开票 -->
                <span>合计开票金额：</span><a-input :disabled="this.$route.query.status" v-model="form.actualAmount" style="width: 100px;"></a-input> 元
              </div>
              <!-- 新增预开票 -->
              <div style="margin-top: 20px" v-if="invoicingType == 3 && !this.$route.query.status">
                <p v-if="this.selectedRowsList1.length > 0 ||this.selectedRowsList2.length > 0 || this.selectedRowsList3.length > 0">
                  <a-popconfirm
                      title="该操作会取消已选中项，确认继续吗？"
                      ok-text="确认"
                      cancel-text="取消"
                      @confirm="addMX(0)"
                  >
                    <a-button type="primary" :disabled="!queryData.customerId || !queryData.invoiceId" >添加开票明细</a-button>
                  </a-popconfirm>
                </p>
                <p v-else>
                  <a-button type="primary" :disabled="!queryData.customerId || !queryData.invoiceId" @click="addMX(1)">添加开票明细</a-button>
                </p>

                <!-- <span>合计开票金额：</span><a-input placeholder="0.00" :disabled="this.$route.query.status" v-model="glAmout" style="width: 100px;"></a-input> 元 -->
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </li>
      <li v-show="current == 1 || this.$route.query.status" style="min-height: 450px;">      
        <!-- 开票信息 -->      
        <a-form-model v-if="!this.$route.query.status"  style="width:100%;border: 1px solid rgb(245, 245, 245); padding: 10px 15px;margin-bottom:16px;">
          <div class="ant-descriptions-title">销售方信息</div>
          <div style="display: flex;">
            <a-form-model-item label="销售方信息" required style="width: 30%;">
            <div style="position: relative;">
              <a-select v-model="form.sellerId"  @change="checkoutSeller" style="width: 100%" placeholder="请选择销售方信息" :disabled="this.$route.query.status == 'rel'">
                <a-select-option :value="item.id" v-for="item in sellerList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </a-form-model-item>
        
          <a-form-model-item label="发票类型" required style="width: 30%;margin:0 20px;">
            <div style="position: relative;">
              <a-select  :disabled="this.$route.query.status == 'rel'" v-model="form.invoiceTypeId" style="width: 100%" placeholder="请选择发票类型">
                <a-select-option :value="item.id" v-for="item in TypeList" :key="item.id" @click="invoiceTypeChange(item.invoiceType)">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </a-form-model-item>

          </div>
        </a-form-model>

        <!-- 买方信息 -->
        <div class="inv-box" v-if="!this.$route.query.status">
          <a-form-model style="border: 1px solid rgb(245, 245, 245); padding: 10px 15px;margin-bottom:16px;">
            <div class="ant-descriptions-title">买方信息</div>
              <div  style="width:100%;display: flex;flex-wrap:wrap;">
                <a-form-model-item label="公司名称" required style="width: 22%;">
                  <span>{{ selectInvoice.invoiceHeading }}</span>
                </a-form-model-item>
                <a-form-model-item label="发票抬头" required style="width: 22%;">
                  <span>{{ selectInvoice.invoiceHeading }}</span>
                </a-form-model-item>
                <a-form-model-item label="统一社会信用代码/纳税人识别号" required style="width: 22%;">
                    <span>{{ selectInvoice.dutyParagraph }}</span>
                </a-form-model-item>
                <a-form-model-item label="开户行" :required="invoiceType == 2" style="width: 22%;">
                    <span>{{ selectInvoice.bank }}</span>
                </a-form-model-item>
                <a-form-model-item label="发票类型" :required="invoiceType == 2" style="width: 22%;">
                    <span>{{ selectInvoice.invoiceTypeName }}</span>
                </a-form-model-item>
                <a-form-model-item label="账号" :required="invoiceType == 2" style="width: 22%;">
                    <span>{{ selectInvoice.bankAccount }}</span>
                </a-form-model-item>
                <a-form-model-item label="地址" :required="invoiceType == 2" style="width: 22%;">
                    <span>{{ selectInvoice.address }}</span>
                </a-form-model-item>
                <a-form-model-item label="电话" :required="invoiceType == 2" style="width: 22%;">
                    <span>{{ selectInvoice.phone }}</span>
                </a-form-model-item>
            </div>
          </a-form-model>
        </div>
        <!-- 销售单数据 -->
          <template v-if="selectedRowsList.length > 0 && !this.$route.query.status">
              <a-descriptions title="发票信息">
                <a-descriptions-item label="可开票金额"><span style="color: green; font-weight: 700;">¥{{ invoiceAmount }}</span></a-descriptions-item>
                <a-descriptions-item label="剩余可开票金额" >
                  <span style="color: red; font-weight: 700;">¥{{ (invoiceAmount - totalActualAmount).toFixed(2) }}</span>
                </a-descriptions-item>
              </a-descriptions>
            </template>
          <!-- 开票明细 -->
          <infoTable :data="sfaInvoiceApplyDetailsContentList" :sellerId="this.form.sellerId" @sendCententList="sendCententList"></infoTable>
         <!-- 备注 -->
          <a-form-item label="发票备注" >
            <a-textarea v-model="form.remarks" :disabled="this.$route.query.isDetail || this.$route.query.status"  
            :placeholder="this.$route.query.isDetail ? '-' : '1、该备注信息会展示在发票上展示，如有需要请录入，不需要则无需填写！2、建议不要超过25个汉字或50个（数字+字母），否则盖章的时候有可能压到！'"></a-textarea>  
          </a-form-item>
          <!-- 说明 -->
          <div class="remarks-box">
            <a-form-item label="开票申请说明" >
              <a-textarea :disabled="this.$route.query.isDetail || this.$route.query.status" v-model="form.applyExplain" placeholder="仅应用于内部审批备注，发票不展示！"></a-textarea>  
            </a-form-item>
          </div>
          <div style="display: flex; justify-content: right;align-items: center;" class="info-two" v-show="current == 1">
            <!-- <span style="margin-right: 10px;font-size: 15px" v-if="invoicingType != 3 && !this.$route.query.status">合计明细开票金额：<span style="color:red;">¥{{ totalActualAmount.toFixed(2)}}</span> </span> -->
            <span style="margin-right: 10px;font-size: 15px" v-if="!this.$route.query.status">合计明细开票金额：<span style="color:red;">¥{{ totalActualAmount.toFixed(2)}}</span> </span>
            <!-- 关联 预开票 -->
            <span style="margin-right: 10px;font-size: 15px" v-if="this.$route.query.status">开票金额：<span style="color:red;">¥{{ totalActualAmount }}</span></span>
            <!-- 新增 预开票 -->
            <!-- <span style="margin-right: 10px;font-size: 15px" v-if="invoicingType == 3 && !this.$route.query.status">开票金额：<span style="color:red;">¥{{ glAmout }}</span></span> -->
            <div style="display: flex;" v-if="!this.$route.query.status">
              <a-button v-if="current != 0" :disabled="current == 0" @click="current--">上一步</a-button>
              <a-button v-if="current != 1  && invoicingType != 3" :disabled="selectedRowsList.length == 0 || !queryData.invoiceId" type="primary" @click="next">下一步</a-button>
              <a-button v-if="current != 1  && invoicingType == 3" :disabled="form.actualAmount == 0 || !form.actualAmount" type="primary" @click="next;">下一步</a-button>
              <a-button v-if="current == 1"  type="primary" @click="save" style="margin-left: 10px;">提交</a-button>
            </div>
            <div style="display: flex;" v-else>
              <a-button v-if="current != 0" :disabled="current == 0" @click="current--">上一步</a-button>
              <a-button v-if="current != 1" type="primary" @click="current++">关联</a-button>
              <a-button v-if="current == 1"  type="primary" @click="saveGl" style="margin-left: 10px;">立即关联</a-button>
            </div>
          </div>
      </li>
    </ul>
    <div  v-if="this.$route.query.status" style="display: flex; justify-content: right;align-items: center">
      <span style="margin-right: 10px;font-size: 15px">开票金额：<span style="color:red;">¥{{ totalActualAmount }}</span></span>
      <a-button  type="primary" @click="saveGl" style="margin-left: 10px;">立即关联</a-button>
    </div>
  </div>
</template>
<script>
import { request } from "@/utils/request";
import {pileColumns,resColumns} from './components/Columns.js'
import infoTable from './components/invoiceInfoTable.vue'
import { EventBus } from '@/utils/event-bus.js';

export default {
  components:{infoTable},
  data() {
    return {
      columns: [
    {title: '序号', customRender: (text, record, index) => index + 1, align: 'center', width: 80,},
      {
        title: "创建时间",
        dataIndex: "createTime",
        scopedSlots: { customRender: "createTime" },
        customRender: (value, row, index) => {
          value = this.formatDateMin(value);
          const obj = {
            children: value,
            attrs: {},
          };
          let result = this.findDuplicateIds(this.dataSource);
          result.forEach((item) => {
            if (item.id == row.id) {
              obj.attrs.rowSpan = 0;
              if (index == item.indexes[0]) {
                obj.attrs.rowSpan = item.indexes.length;
              }
            }
          });
          return obj;
        },
        align: "center",
        width: 150,
      },
      {
        title: "提交时间",
        dataIndex: "submitTime",
        customRender: (value, row, index) => {
          if (value) {
            value = this.formatDateMin(value);
          } else {
            value = "-";
          }
          const obj = {
            children: value,
            attrs: {},
          };
          let result = this.findDuplicateIds(this.dataSource);
          result.forEach((item) => {
            if (item.id == row.id) {
              obj.attrs.rowSpan = 0;
              if (index == item.indexes[0]) {
                obj.attrs.rowSpan = item.indexes.length;
              }
            }
          });
          return obj;
        },
        align: "center",
        width: 150,
      },
      {
        title: "客户名称",
        dataIndex: "customerName",
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {},
          };
          let result = this.findDuplicateIds(this.dataSource);
          result.forEach((item) => {
            if (item.id == row.id) {
              obj.attrs.rowSpan = 0;
              if (index == item.indexes[0]) {
                obj.attrs.rowSpan = item.indexes.length;
              }
            }
          });
          return obj;
        },
        // ellipsis: true,
        align: "center",
        width: 150,
      },
      {
        title: "可开票金额",
        dataIndex: "surplusAmount",
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {},
          };
          let result = this.findDuplicateIds(this.dataSource);
          result.forEach((item) => {
            if (item.id == row.id) {
              obj.attrs.rowSpan = 0;
              if (index == item.indexes[0]) {
                obj.attrs.rowSpan = item.indexes.length;
              }
            }
          });
          return obj;
        },
        align: "center",
        ellipsis: true,
        width: 150,
      },
      {
        title: "已开票金额",
        dataIndex: "alreadyAmount",
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {},
          };
          let result = this.findDuplicateIds(this.dataSource);
          result.forEach((item) => {
            if (item.id == row.id) {
              obj.attrs.rowSpan = 0;
              if (index == item.indexes[0]) {
                obj.attrs.rowSpan = item.indexes.length;
              }
            }
          });
          return obj;
        },
        align: "center",
        width: 150,
      },
      {
        title: "实收金额",
        dataIndex: "invoiceAmount",
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {},
          };
          let result = this.findDuplicateIds(this.dataSource);
          result.forEach((item) => {
            if (item.id == row.id) {
              obj.attrs.rowSpan = 0;
              if (index == item.indexes[0]) {
                obj.attrs.rowSpan = item.indexes.length;
              }
            }
          });
          return obj;
        },
        align: "center",
        ellipsis: true,
        width: 150,
      },
      // {
      //   title: "开票状态",
      //   dataIndex: "invoiceAmount",
      //   customRender: (value, row, index) => {
      //     const obj = {
      //       children: value,
      //       attrs: {},
      //     };
      //     let result = this.findDuplicateIds(this.dataSource);
      //     result.forEach((item) => {
      //       if (item.id == row.id) {
      //         obj.attrs.rowSpan = 0;
      //         if (index == item.indexes[0]) {
      //           obj.attrs.rowSpan = item.indexes.length;
      //         }
      //       }
      //     });
      //     return obj;
      //   },
      //   align: "center",
      //   ellipsis: true,
      //   width: 150,
      // },
      {
        title: "客户经理",
        dataIndex: "staffName",
        // customRender: renderContent,
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {},
          };
          let result = this.findDuplicateIds(this.dataSource);
          result.forEach((item) => {
            if (item.id == row.id) {
              obj.attrs.rowSpan = 0;
              if (index == item.indexes[0]) {
                obj.attrs.rowSpan = item.indexes.length;
              }
            }
          });
          return obj;
        },
        align: "center",
        width: 150,
      },
      {
        title: "销售订单号",
        dataIndex: "code",
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {},
          };
          let result = this.findDuplicateIds(this.dataSource);
          result.forEach((item) => {
            if (item.id == row.id) {
              obj.attrs.rowSpan = 0;
              if (index == item.indexes[0]) {
                obj.attrs.rowSpan = item.indexes.length;
              }
            }
          });
          return obj;
        },
        align: "center",
        width: 220,
      },
      {
        title: "出库批次号",
        dataIndex: "outBatchCode",
        align: "center",
        width: 220,
      },
   
    ],//销售单
      pileColumns:pileColumns,//货堆
      resColumns:resColumns,//宅配
      current: 0,
      pileDataSource:[],//货堆数据
      pileLoading:false,//货堆loading
      customerData: [],//客户列表
      invoiceList:[],//发票
      dataSource:[],//销售单列表
      resDataSource:[],//宅配列表
      queryData: {
        page: 0,
        size: 20,
        // f5b8a7ae-2bf6-4265-bb73-817ad6ebc17b
      },
      selectedRowsList:[],
      selectedRowsList1:[],
      selectedRowsList2:[],
      selectedRowsList3:[],
      selectedRowsKeys1:[],
      selectedRowsKeys2:[],
      selectedRowsKeys3:[],
      selectRowsObj1:{},
      selectRowsObj2:{},
      selectRowsObj3:{},
      sellerList:[],//主体公司
      // 列表总页数
      total:0,
      pileTotal:0,
      resTotal:0,
      // 列表加载中
      tabLoading:false,
      tabOutLoading:false,
      resLoading:false,
      // 货堆列表参数
      pileParams:{
        page:0,
        size:10,
        type:20,
        buyCustomerId:""
      },
      // 宅配列表参数
      resParams:{
        page:0,
        size:10,
        type:21,
        buyCustomerId:""
      },
      selectInvoice:{},//选中的发票信息
      TypeList:[],
      // 提交表单
      form:{
        amount:0,//开票金额
        sfaInvoiceApplyDetailsList:[],
      },
      tempSfaInvoiceApplyDetailsList:[],//接收子组件传来的sfaInvoiceApplyDetailsList
      invoiceType:0,//发票类型
      invoiceAmount:0,//可开票金额
      glAmout:'',//关联开票金额
      totalActualAmount:0,//合计明细开票金额
      sfaInvoiceApplyDetailsContentList:[],
      invoicingType:1,
      EditSfaInvoiceApplyDetailsList:[],
    };
  },
  mounted() {
    this.getUser();//客户列表
    this.getSellerList();//主体公司
    // 详情
    this.getDetails();
  },  
  watch:{
    'form.sellerId':function(newVal, oldValue){
      console.log(newVal,oldValue, 'newVal');
        this.getInoviceTypeList();
    }
  },
  computed: {
    rowSelection1() {
      return {
        selectedRowKeys: this.selectedRowsKeys1,
        onChange: this.onSelectChange1,
        getCheckboxProps: record => ({
          props: {
            disabled:record.surplusAmount <= 0,
          },
        }),
      };
    },
    rowSelection2() {
      return {
        selectedRowKeys: this.selectedRowsKeys2,
        onChange: this.onSelectChange2,
        getCheckboxProps: record => ({
          props: {
            disabled:record.surplusAmount <= 0 || record.invoice == 2,
          },
        }),
      };
    },
    rowSelection3() {
      return {
        selectedRowKeys: this.selectedRowsKeys3,
        onChange: this.onSelectChange3,
        getCheckboxProps: record => ({
          props: {
            disabled:record.surplusAmount <= 0 || record.invoice == 2,
          },
        }),
      };
    },
  },  

  methods: {
    addMX(i){
      if(i == 0) {
        this.form.sfaInvoiceApplyDetailsContentList = []
        this.sfaInvoiceApplyDetailsContentList = []
        this.totalActualAmount = 0
      }
      this.selectRowsObj = {};
      this.selectedRowsList = [];
      this.selectedRowsList1 = [];
      this.selectedRowsList2 = [];
      this.selectedRowsList3 = [];
      this.selectedRowsKeys1 = [];
      this.selectedRowsKeys2 = [];
      this.selectedRowsKeys3 = [];
      this.form.sfaInvoiceApplyDetailsList = [];
      this.form.actualAmount = 0;
      this.invoiceAmount = 0;
      this.current++;

      // this.form.sfaInvoiceApplyDetailsContentList = []
      // this.sfaInvoiceApplyDetailsContentList = []

    },
    next(){
      if(this.form.status == 5 ) {
        this.form.sfaInvoiceApplyDetailsContentList = []
        this.sfaInvoiceApplyDetailsContentList = []
        this.totalActualAmount = 0
      }
      this.current++
    },
    closePage(){
      EventBus.$emit('itemRemoved');
    },
    getDetails(){
      if(this.$route.query.id) {
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/' + this.$route.query.id , 'get').then(res => {
          this.form = res.data.data;
          console.log(this.form,'this.form')
          this.EditSfaInvoiceApplyDetailsList = this.form.sfaInvoiceApplyDetailsList
          this.queryData.customerId = this.form.customerId;
          this.queryData.invoiceId = this.form.invoiceId;
          // 开票明细
          this.sfaInvoiceApplyDetailsContentList = this.form.sfaInvoiceApplyDetailsContentList;
          // 明细开票金额
          this.sfaInvoiceApplyDetailsContentList.forEach(item=>{
            this.totalActualAmount = this.totalActualAmount + item.money
          })
          // 获取该客户发票列表
          this.getInoviceList();
            this.editList();
            this.editPileData()
            this.editResData()
          // 关联
          if(this.$route.query.status) {
            this.invoicingType = 1;
            this.glAmout = this.form.actualAmount
            this.getList();
            setTimeout(()=>{
              this.selectInvoiceChange(this.form.invoiceId);
            },1500)
          }
          // 编辑
          if(!this.$route.query.status) {
            //  预开票
            if(this.form.type == 2) {
              this.glAmout = this.form.actualAmount
            }
          }
        });
      }
    },
    invoiceTypeChange(type){
      this.invoiceType = type;
    },
    editList(){
      this.getList().then(() => {
          this.selectInvoiceChange(this.form.invoiceId);
          let idxArr = [];
          let newArr = []
          this.form.invoiceAmount = this.form.amount;
          // 开票明细 绑定
          console.log(this.dataSource,'this.dataSource')
          console.log(this.form.sfaInvoiceApplyDetailsList,'this.form.sfaInvoiceApplyDetailsList')
          this.EditSfaInvoiceApplyDetailsList.forEach(item => {
            this.dataSource.forEach((da,i)=>{
              if(da.code == item.detailCode){
                idxArr.push(i);
                newArr.push(da)
                this.onSelectChange1(idxArr,newArr)
              }
            })
              // item.invoiceAmount = item.documentAmount;
              // item.sellerId = this.form.sellerId;
              // idxArr.push(index);
              // newArr.push(item)
              // this.onSelectChange1(idxArr,newArr)
            });
        });
    }, 
    editPileData(){
      // 货堆编辑初始化
      this.getPileList().then(() => {
          this.selectInvoiceChange(this.form.invoiceId);
          let idxArr = [];
          let newArr = []
          this.form.invoiceAmount = this.form.amount;
             // 开票明细 绑定
            console.log(this.pileDataSource,'this.pileDataSource')
            console.log(this.form.sfaInvoiceApplyDetailsList,'this.this.form.sfaInvoiceApplyDetailsList')
          this.EditSfaInvoiceApplyDetailsList.forEach(item => {
            this.pileDataSource.forEach((da,i)=>{
              if(da.code == item.detailCode){
                idxArr.push(i);
                newArr.push(da)
                this.onSelectChange2(idxArr,newArr)
              }
            })
          });
        });
    },
    editResData(){
      // 宅配编辑初始化
      this.getResList().then(() => {
          this.selectInvoiceChange(this.form.invoiceId);
          let idxArr = [];
          let newArr = []
          this.form.invoiceAmount = this.form.amount;
           // 开票明细 绑定
          this.EditSfaInvoiceApplyDetailsList.forEach(item => {
            this.resDataSource.forEach((da,i)=>{
              if(da.code == item.detailCode){
                idxArr.push(i);
                newArr.push(da)
                this.onSelectChange3(idxArr,newArr)
              }
            })
          });
        });
    },
    checkoutSeller(e){
      this.form.sellerId = e;
      delete this.form.invoiceTypeId
      this.getInoviceTypeList();
    },
  
    checkEditable(arr) {
      for(let i = 0; i < arr.length; i++) {
          if(arr[i].editable) {
            this.$message.warning('请保存发票明细！')
            return false;
          }
      }
      return true;
    },
    saveGl() {
      this.$confirm({
        title: '提示',
        content: '预开票关联销售单仅可关联一次，请确认预开票金额小于等于销售单可开票金额！',
        onOk:()=> {
            this.save();
        },
        onCancel() {},
      });
    },
    save(){
      this.form.customerId = this.queryData.customerId; //客户id
      this.form.invoiceType = this.selectInvoice.invoiceType;//专票/普票
      this.form.amount = this.invoiceAmount; // 合计开票金额
      this.form.invoiceId=this.queryData.invoiceId;
      if(!this.form.invoiceTypeId) {
        this.$message.warning('请选择发票类型！')
        return false;
      }
      this.form.actualAmount = this.totalActualAmount;//实际开票金额

      if(!this.checkEditable(this.sfaInvoiceApplyDetailsContentList)) {
        return false;
      }

         // 专票情况下 判断每个发票信息是否为空
      if(this.invoiceType == 2) {
        if(!this.selectInvoice.invoiceHeading || !this.selectInvoice.dutyParagraph || !this.selectInvoice.bank || !this.selectInvoice.invoiceTypeName || !this.selectInvoice.bankAccount || !this.selectInvoice.address || !this.selectInvoice.phone) {
          this.$message.warning('发票信息不全，需将发票信息中标记*号的字段补齐后可进行提交！')
          return false;
        }
      }
      this.form.invoiceId=this.queryData.invoiceId;
      this.form.type = 1; //1申请单 2预开票
      // 预开票
      if(this.invoicingType == 3) {
        // this.form.actualAmount = this.glAmout;
        this.form.type = 2;
      }
      // 新增 
     if(!this.$route.query.status && !this.$route.query.id) {
        if(this.form.actualAmount > this.form.amount && this.invoicingType != 3) {
          this.$message.error('明细合计开票金额已大于可开票金额，无法提交！')
          return false;
        }
        this.addSave();
      }
      // 编辑
      if(this.$route.query.id && !this.$route.query.status) {
        if(this.form.actualAmount > this.form.amount && this.invoicingType != 3) {
          this.$message.error('明细合计开票金额已大于可开票金额，无法提交！')
          return false;
        }
        this.editSave();
      }
      // 关联
      if(this.$route.query.status) {
        if(this.selectedRowsList.length > 0 && this.invoiceAmount - this.totalActualAmount < 0) {
          this.$message.error('明细合计开票金额已大于可开票金额，无法提交！')
          return false;
        }
        if(this.selectedRowsList.length == 0) {
          this.$message.error('未选择关联项！')
          return false;
        }
        this.form.sfaInvoiceApplyDetailsContentList.forEach(item=>{
          delete item.id
        })
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/correlationReceipt/'+this.form.id,'post',this.form).then(res=>{
            if(res.data.code == 200) {
              this.$message.success('操作成功！');
              this.closePage();
              this.$router.push('/sfa_workOrder/InvoiceApplyList')
            } else {
              this.$message.error(res.data.message);
            }
        })
      }
      console.log(this.form,'form')
      // 新增
    },
    // 编辑
    editSave(){
      this.form.sfaInvoiceApplyDetailsContentList.forEach(item=>{
          delete item.id
      })
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/update','post',this.form).then(res=>{
            if(res.data.code == 200) {
              this.$message.success('操作成功！');
              this.closePage();
              this.$router.push('/sfa_workOrder/InvoiceApplyList')
            } else {
              this.$message.error(res.data.message);
            }
          })
    },
    addSave() {
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/save', 'post', this.form).then(res => {
        if (res.data.code == 200) {
          this.$message.success('操作成功！');
          this.closePage();
          this.$router.push('/sfa_workOrder/InvoiceApplyList')
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    
    selectChange(){
      delete this.queryData.invoiceId;
      this.clearData();
      //获取该客户发票列表
      this.getInoviceList();
      this.getList();// 获取销售单数据
      this.getSellerList();//获取卖方主体
      this.getPileList();//获取货堆列表
      this.getResList();//获取宅配列表
    },
    clearData(){
      this.selectRowsObj = {};
      this.selectedRowsList = [];
      this.selectedRowsList1 = [];
      this.selectedRowsList2 = [];
      this.selectedRowsList3 = [];
      this.selectedRowsKeys1 = [];
      this.selectedRowsKeys2 = [];
      this.selectedRowsKeys3 = [];
      this.form.sfaInvoiceApplyDetailsList = [];
      this.form.actualAmount = 0;
      this.invoiceAmount = 0;
    },
    selectInvoiceChange(id){
      this.invoiceList.forEach(item=>{
        if(id == item.id) {
          this.selectInvoice = item;
        }
      })
    },
    // 切换tab
    callback(key) {
      // 非关联
      if(key == 3 && !this.$route.query.status) {
        // this.selectRowsObj = {};
        // this.selectedRowsList = [];
        // this.selectedRowsList1 = [];
        // this.selectedRowsList2 = [];
        // this.selectedRowsList3 = [];
        // this.selectedRowsKeys1 = [];
        // this.selectedRowsKeys2 = [];
        // this.selectedRowsKeys3 = [];
        // this.form.sfaInvoiceApplyDetailsList = [];
        // this.form.actualAmount = 0;
        // this.invoiceAmount = 0;
      } else {
        this.glAmout = '';
      }
      console.log(key,'keyyyy')
      this.invoicingType = key;
    },
    // 选中销售单
    onSelectChange1(selectedRowKeys, selectedRows) {
      console.log(`selectedRowKeys1: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      this.selectedRowsKeys1 = selectedRowKeys;
      this.selectedRowsList1 = selectedRows;
      this.selectRowsObj1 = selectedRows[0];
      this.salcActualAmount()
    },
    onSelectChange2(selectedRowKeys, selectedRows) {
      console.log(`selectedRowKeys2: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      this.selectedRowsKeys2 = selectedRowKeys;
      this.selectedRowsList2 = selectedRows;
      this.selectRowsObj2 = selectedRows[0];
      this.salcActualAmount()
    },
    onSelectChange3(selectedRowKeys, selectedRows) {
      console.log(`selectedRowKeys3: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      this.selectedRowsKeys3 = selectedRowKeys;
      this.selectedRowsList3 = selectedRows;
      this.selectRowsObj3 = selectedRows[0];
      this.salcActualAmount()
    },
    // 开票详情数组
    sendCententList(data){
      this.form.sfaInvoiceApplyDetailsContentList = data;
      this.totalActualAmount = 0;
      this.sfaInvoiceApplyDetailsContentList = data;
      data.forEach(item=>{
        this.totalActualAmount =this.totalActualAmount +  (item.money - 0)
        this.$set(this, 'totalActualAmount', this.totalActualAmount);
      })
    },
    // form.actualAmount
    // 合并选中列表 计算可开票合计金额
    salcActualAmount(){
      this.invoiceAmount = 0;
      this.selectedRowsList = [];
      this.form.sfaInvoiceApplyDetailsList = [];
      if(this.selectedRowsList1.length > 0) {
        this.selectedRowsList1.forEach(item=>{
          this.invoiceAmount =(this.invoiceAmount - 0) +  (item.surplusAmount-0);
          item.invoicingType = 1;//销售单
          this.selectedRowsList.push(item);
          this.form.sfaInvoiceApplyDetailsList.push({
            invoicingType:item.invoicingType,
            detailCode:item.code
          });
        })
      }
      if(this.selectedRowsList2.length > 0) {
        this.selectedRowsList2.forEach(item=>{
          this.invoiceAmount =(this.invoiceAmount - 0) +  (item.surplusAmount-0);
          item.invoicingType = 4;//货堆
          this.selectedRowsList.push(item);
          this.form.sfaInvoiceApplyDetailsList.push({
            invoicingType:item.invoicingType,
            detailCode:item.code
          });
        })
      }
      if(this.selectedRowsList3.length > 0) {
        this.selectedRowsList3.forEach(item=>{
          this.invoiceAmount =(this.invoiceAmount - 0) +  (item.surplusAmount-0);
          item.invoicingType = 5;//宅配
          this.selectedRowsList.push(item);
          this.form.sfaInvoiceApplyDetailsList.push({
            invoicingType:item.invoicingType,
            detailCode:item.code
          });
        })
      }

      // 关联
      // if(this.$route.query.status) {
        // this.invoiceAmount =
      // }
    },
    // 卖方主体
    getSellerList(){
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/chart/card/seller/list', 'get').then(res => {
        this.sellerList = res.data.data;
        this.form.sellerId = this.sellerList[0].id
        this.getInoviceTypeList()
      })
    },
    handlePageChange(page) {
      this.queryData.page = page - 1;
      this.currentPage = page;
      this.getList()
    },
    handlePageChange1(page) {
      this.pileParams.page = page - 1;
      this.pileParams.currentPage = page;
      this.getPileList()
    },
    handlePageChange2(page) {
      this.resParams.page = page - 1;
      this.resParams.currentPage = page;
      this.getResList()
    },
    
    // 获取该公司发票种类
    getInoviceTypeList(){
      if(this.form.sellerId) {
        request(process.env.VUE_APP_API_BASE_URL + "sfa/invoice/type/list/"+this.form.sellerId,"get").then((res) => {
          if(res.data.code == 200) {
            this.TypeList = res.data.data;
          }
        })
      }
    },
    // 获取货堆列表
    getPileList(){
      this.pileParams.buyCustomerId = this.queryData.customerId
      return new Promise((resolve) => {
        if (this.queryData.customerId) {
            this.pileLoading = true;
            request(process.env.VUE_APP_API_BASE_URL + "sfa/order/ka/listByCustomerId","post",this.pileParams).then((res) => {
              this.pileLoading = false;
              let data = res.data;
              this.pileDataSource = data.data.data;
              this.pileParams.page = data.data.page;
              this.pileParams.size = data.data.size;
              this.pileTotal = data.data.total;
              resolve(); // 表示 getList() 方法执行完毕
          });
        }
      });
    },
      // 获取宅配列表
      getResList(){
        this.resParams.buyCustomerId = this.queryData.customerId
        return new Promise((resolve) => {
          if (this.queryData.customerId) {
            console.log(111)
              this.resLoading = true;
              request(process.env.VUE_APP_API_BASE_URL + "sfa/order/ka/listByCustomerId","post",this.resParams).then((res) => {
                this.resLoading = false;
                let data = res.data;
                this.resDataSource = data.data.data;
                this.resParams.page = data.data.page;
                this.resParams.size = data.data.size;
                this.resTotal = data.data.total;
                resolve(); // 表示 getList() 方法执行完毕
            });
          }
        });
    },    
    // 获取用户发票列表
    getInoviceList(){
      request(process.env.VUE_APP_API_BASE_URL + "sfa/invoice/list/"+this.queryData.customerId,"get").then((res) => {
        this.invoiceList = res.data.data;
      });
    },
    getUser() {
      // 客户列表
      request(process.env.VUE_APP_API_BASE_URL + "sfa/customer/list/all","get").then((res) => {
        this.customerData = res.data.data;
      });
    },
    // 销售单数据
    getList() {
      return new Promise((resolve) => {
        if (this.queryData.customerId) {
            this.tabLoading = true;
            request(process.env.VUE_APP_API_BASE_URL + "sfa/saledocket/listbycustomerid","post",this.queryData).then((res) => {
              this.tabLoading = false;
              let data = res.data;
              this.dataSource = data.data.data;
              let resultMap = new Map();
              this.dataSource.forEach(item => {
                  if (resultMap.has(item.id)) {
                      let existingItem = resultMap.get(item.id);
                      // 检查属性是否相同
                      let same = true;
                      Object.keys(item).forEach(key => {
                          if (existingItem[key] !== item[key]) {
                              same = false;
                              // 如果属性值不同，则拼接到一个属性中
                              existingItem[key] = existingItem[key] + ', ' + item[key];
                          }
                      });
                      // 如果所有属性都相同，只保留一个对象
                      if (same) {
                          resultMap.set(item.id, existingItem);
                      }
                  } else {
                      resultMap.set(item.id, item);
                  }
              });
                    // 将 resultMap 转换为数组
             let mergedArray = Array.from(resultMap.values());
             this.dataSource = mergedArray;
              this.queryData.page = data.data.page;
              this.queryData.size = data.data.size;
              this.total = data.data.total;
              // this.selectedRowsList = [];
              // delete this.queryData.invoiceId

              resolve(); // 表示 getList() 方法执行完毕
          });
        }
      });
    },
    findDuplicateIds(array) {
      const idMap = {};
      const duplicates = [];
      array.forEach((item, index) => {
          if (idMap[item.id] === undefined) {
              idMap[item.id] = index;
          } else {
              if (!duplicates.some(duplicate => duplicate.id === item.id)) {
                  duplicates.push({ id: item.id, indexes: [idMap[item.id], index] });
              } else {
                  const existingDuplicate = duplicates.find(duplicate => duplicate.id === item.id);
                  existingDuplicate.indexes.push(index);
              }
          }
      });
      return duplicates;
    },
  
      // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
      return result
    },
    formatDateMin(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
        return result;
      },
  },
};
</script>
<style scoped>
.page {
  width: 100%;
  min-height: calc(100vh - 140px);
  /* display: flex; */
  /* overflow: hidden; */
  padding: 20px;
  box-sizing: border-box;
}
.page ul {
  padding: 0;
}
.page-table {
  min-height: 40vh;
}
.page li {
  height: 100%;
}




.page-box {
  margin-left: auto;
  padding-right: 46px;
  margin-top: 10px;
}

.sale-list {
  display: flex;
  font-size: 15px;
  font-weight: 500;
}
.sale-list p {
  margin-right: 20px;
}

.page-info {
  position: absolute;
  right:10px;
  top:120px;
  z-index: 99999;
  padding-right: 20px;
}
.info-two {
}

.inv-box ::v-deep .ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.65);
}
.inv-box ::v-deep .ant-form-item-children {
  font-weight: 500;
  color: #000;
}

.remarks-box ::v-deep .ant-form-item-label{
  color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
    font-size: 16px;
}

.tips-rad {
  color: #fff;
  min-width: 14px;
  height: 14px;
  text-align: center;
  line-height: 14px;
  display: inline-block;
  //padding: 4px 4px;
  font-size: 12px;
  background: red;
  margin-right: 4px;
  border-radius: 100%;
}
</style>

